<template>
  <div class="scanPage">
     <scan-code></scan-code>
  </div>
</template>

<script>
import ScanCode from "@/components/ScanCode.vue";
export default {
  data() {
    return {};
  },
  components:{
    ScanCode
  }
};
</script>

<style lang='less' scoped>
</style>

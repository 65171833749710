<template>
  <div class="scan">
    <div id="bcid"></div>
  </div>
</template>

<script>
let scan = null;
export default {
  data() {
    return {
      codeUrl: "",
    };
  },

  beforeDestroy(){
   if(window.plus){
      scan.close();
    }
  },
  mounted() {
    this.startRecognize();
  },
  methods: {
    // 创建扫描控件
    startRecognize() {
      let that = this;
      if (!window.plus) return;
      scan = new plus.barcode.Barcode("bcid");
      that.startScan();
      scan.onmarked = onmarked;

      function onmarked(type, result, file) {
        switch (type) {
          case plus.barcode.QR:
            type = "QR";
            break;
          case plus.barcode.EAN13:
            type = "EAN13";
            break;
          case plus.barcode.EAN8:
            type = "EAN8";
            break;
          default:
            type = "其它" + type;
            break;
        }
        result = result.replace(/\n/g, "");
        that.codeUrl = result;
        localStorage.setItem('ScanedUrl',result); 
        that.closeScan();
      }
    },
    // 开始扫描
    startScan() {
      if (!window.plus) return;
      scan.start();
    },
    // 关闭条码识别控件
    closeScan() {
      if (!window.plus) return;
      scan.close();
      this.$router.go(-1);
    }
  },
};
</script>
<style lang="less">
.scan {
  height: 100%;

  #bcid {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    color: #fff;
    background: #ccc;
  }
}
</style>
